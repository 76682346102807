import React, { useEffect, useState, useRef, Suspense, lazy } from 'react';
import { Route, Routes, Navigate, useSearchParams, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';

import currencySymbols from './assets/currencySymbols.json';
import countryToCurrency from './assets/countryToCurrency.json';
// import serviceCodes from './assets/servicesCodes.json'

const platformLogos = {
  Instagram: '/assets/platformLogos/Instagram.png',
  Facebook: '/assets/platformLogos/Facebook.png',
  Youtube: '/assets/platformLogos/Youtube.png',
  TikTok: '/assets/platformLogos/Tiktok.png',
  Spotify: '/assets/platformLogos/Spotify.png',
  X: '/assets/platformLogos/X.png',
  LinkedIn: '/assets/platformLogos/Linkedin.png',
  'Reviews And Ratings': '/assets/platformLogos/Reviews.png',
  'App Installs': '/assets/platformLogos/AppInstall.png',
};

const mongoURI = process.env.REACT_APP_MONGOURI;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPK);
const api = `${window.location.origin}/api`;

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [platform, setPlatform] = useState(null);
  const [serviceFromBelow, setServiceFromBelow] = useState(null);
  const [lastSuccessfulOrderDetails, setLastSuccessfulOrderDetails] = useState(null);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currencyConversionRates, setCurrencyConversionRates] = useState({});
  const [servicesData, setServicesData] = useState({});
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState(null);
  const quantityRef = useRef(null);
  const [searchParams] = useSearchParams();
  const location = useLocation(); // Get current location

  const serviceIcon = '/assets/serviceIcon.png';
  const urlImg = '/assets/web.png';
  const quantityImg = '/assets/quantity.png';
  const emailIcon = '/assets/email.png';
  const couponIcon = '/assets/coupon.png';
  const whyIcon = '/assets/why.png';

  // All other components are lazy-loaded
  const Services = lazy(() => import('./components/Services'));
  const Testimonials = lazy(() => import('./components/Testimonials'));
  const Footer = lazy(() => import('./components/Footer'));
  const OrderConfirmationModal = lazy(() => import('./components/OrderConfirmationModal'));
  const FooterIcon = lazy(() => import('./components/FooterIcon'));
  const HighlightsSection = lazy(() => import('./components/HighlightsSection'));
  const Chatbot = lazy(() => import('./components/Chatbot'));
  const OrderTracking = lazy(() => import('./components/OrderTracking'));
  const ReviewComponent = lazy(() => import('./components/ReviewComponent'));
  const CustomOrderOffsite = lazy(() => import('./components/CustomOrderOffsite'));
  const AdminOrders = lazy(() => import('./components/AdminOrders'));
  const Reviews = lazy(() => import('./components/Reviews'));
  const CustomOrder = lazy(() => import('./components/CustomOrder'));
  const UnsubscribePage = lazy(() => import('./components/UnsubscribePage'));
  const EmailActions = lazy(() => import('./components/EmailActions'));
  const Conversations = lazy(() => import('./components/Conversations'));
  const Login = lazy(() => import('./components/Login'));

  const handlePlatformSelect = (newPlatform) => {
    if (newPlatform) {
      setPlatform(newPlatform);
      if (newPlatform === platform && quantityRef) {
        quantityRef.current?.focus();
        quantityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const convertTime = (minutes) => {
    if (minutes >= 1440) {
      const days = Math.floor(minutes / 1440);
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  };

  const getCurrencyConversionRates = () => {
    fetch('https://api.exchangerate-api.com/v4/latest/USD').then(response => response.json())
      .then(data => {
        data.rates.USD = 1;
        setCurrencyConversionRates(data.rates);
      })
      .catch(error => {
        console.error('Error fetching currency conversion rates:', error);
      });
  };

  const getCountry = () => {
    fetch(`${mongoURI}geolocation`).then(response => response.json())
      .then((response) => {
        const countryCode = response.countryCode;
        const userCurrency = countryToCurrency[countryCode];  // Map country to currency
        setCountry(countryCode);
        setCurrency(userCurrency || 'USD');  // Fallback to USD only if userCurrency is not available
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
        setCurrency('USD');  // Fallback if API call fails
      });
  };

  // const saveServices = async () => {
  //   try {
  // const response = await fetch(api, {
  //   method: 'POST',
  //   headers: {
  //     'Content-Type': 'application/json',
  //   },
  //   body: JSON.stringify({ task: "services", action: 'services', serviceCodes }),
  // }).then(response => response.json());

  //     const services = { services: response[0] };

  //     // Save the services to MongoDB
  //     const response1 = await await fetch(`${mongoURI}saveServicesData`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(services),
  //     }).then(response => response.json())

  //     console.log('Saved services to MongoDB:', response1);
  //   } catch (error) {
  //     console.error('Error saving services:', error);
  //   }
  // };

  useEffect(() => {
    const getServicesDataOld = async () => {
      try {
        const response = await fetch(`${mongoURI}getServicesData`).then(response => response.json());
        setServicesData(response.findResult);
      }
      catch (error) {
        console.error('Error fetching services:', error);
      }
    };
    // saveServices();
    getServicesDataOld();
    getCountry();
    getCurrencyConversionRates();
  }, []);

  useEffect(() => {
    // Only call the email lead API if on the home route
    if (location.pathname === "/") {
      const email = searchParams.get('email');
      if (email) {
        fetch(`${mongoURI}EmailLead`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email }),
        })
          .then(response => {
            console.log('Email lead recorded:', response.data);
          })
          .catch(error => {
            console.error('Error recording email lead:', error);
          });
      }
    }
  }, [location, searchParams]);

  useEffect(() => {
    if (servicesData) {
      setPlatform(Object.keys(servicesData)[0]);
    }
  }, [servicesData]);

  return (
    <>
      <Header
        mongoURI={mongoURI}
        setIsChatOpen={setIsChatOpen}
        handlePlatformSelect={handlePlatformSelect}
        authToken={authToken}
        setAuthToken={setAuthToken}
      />
      {/* Header is lazy-loaded, so wrap it in a Suspense */}
      <div className='main'>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route
              path="/"
              element={
                <>
                  <Hero
                    platform={platform}
                    handlePlatformSelect={handlePlatformSelect}
                    servicesData={servicesData}
                    platformLogos={platformLogos}
                    serviceIcon={serviceIcon}
                    urlImg={urlImg}
                    quantityImg={quantityImg}
                    setLastSuccessfulOrderDetails={setLastSuccessfulOrderDetails}
                    setShowOrderConfirmation={setShowOrderConfirmation}
                    currencyConversionRates={currencyConversionRates}
                    mongoURI={mongoURI}
                    couponIcon={couponIcon}
                    currencySymbols={currencySymbols}
                    country={country}
                    currency={currency}
                    api={api}
                    convertTime={convertTime}
                    quantityRef={quantityRef}
                    serviceFromBelow={serviceFromBelow}
                    setServiceFromBelow={setServiceFromBelow}
                  />
                  {/* All other home-page sections are lazy-loaded */}
                  <Suspense>
                    <Services
                      handlePlatformSelect={handlePlatformSelect}
                      servicesData={servicesData}
                      platformLogos={platformLogos}
                      setServiceFromBelow={setServiceFromBelow}
                    />
                    <Testimonials handlePlatformSelect={handlePlatformSelect} platform={platform} />
                    <HighlightsSection />
                  </Suspense>
                </>
              }
            />

            {/* Admin route */}
            <Route
              path="/admin"
              element={
                <Suspense>
                  {!authToken ? (
                    <Login setAuthToken={setAuthToken} mongoURI={mongoURI} />
                  ) : (
                    <AdminOrders
                      mongoURI={mongoURI}
                      currencyConversionRates={currencyConversionRates}
                    />
                  )}
                </Suspense>
              }
            />

            {/* Order Tracking */}
            <Route
              path="/order-tracking"
              element={
                <Suspense>
                  <OrderTracking
                    servicesData={servicesData}
                    platformLogos={platformLogos}
                    urlImg={urlImg}
                    emailIcon={emailIcon}
                    mongoURI={mongoURI}
                    convertTime={convertTime}
                  />
                </Suspense>
              }
            />

            {/* Review */}
            <Route
              path="/review"
              element={
                <Suspense>
                  <ReviewComponent mongoURI={mongoURI} />
                </Suspense>
              }
            />

            {/* Get Reviews */}
            <Route
              path="/getReviews"
              element={
                <Suspense>
                  {!authToken ? (
                    <Login setAuthToken={setAuthToken} mongoURI={mongoURI} />
                  ) : (
                    <Reviews mongoURI={mongoURI} />
                  )}
                </Suspense>
              }
            />

            {/* Custom Order */}
            <Route
              path="/custom-order"
              element={
                <Suspense>
                  <CustomOrder
                    currencyConversionRates={currencyConversionRates}
                    mongoURI={mongoURI}
                    currencySymbols={currencySymbols}
                    country={country}
                    currency={currency}
                    couponIcon={couponIcon}
                    emailIcon={emailIcon}
                    whyIcon={whyIcon}
                  />
                </Suspense>
              }
            />

            {/* Offsite Order */}
            <Route
              path="/offsite-order"
              element={
                <Suspense>
                  <CustomOrderOffsite currency={currency} />
                </Suspense>
              }
            />

            {/* Unsubscribe */}
            <Route
              path="/unsubscribe"
              element={
                <Suspense>
                  <UnsubscribePage mongoURI={mongoURI} />
                </Suspense>
              }
            />

            {/* Email Actions */}
            <Route
              path="/emailactions"
              element={
                <Suspense>
                  <EmailActions mongoURI={mongoURI} />
                </Suspense>
              }
            />

            {/* Conversations */}
            <Route
              path="/conversations"
              element={
                <Suspense>
                  {!authToken ? (
                    <Login setAuthToken={setAuthToken} mongoURI={mongoURI} />
                  ) : (
                    <Conversations mongoURI={mongoURI} />
                  )}
                </Suspense>
              }
            />

            {/* Default redirect */}
            <Route
              path="*"
              element={<Navigate to="/" />}
            />
          </Routes>
        </Elements>
      </div>

      {/* Order Confirmation Modal */}
      <Suspense fallback={<></>}>
        {showOrderConfirmation && (
          <OrderConfirmationModal
            showOrderConfirmation={showOrderConfirmation}
            lastSuccessfulOrderDetails={lastSuccessfulOrderDetails}
            setShowOrderConfirmation={setShowOrderConfirmation}
            mongoURI={mongoURI}
            currencySymbols={currencySymbols}
            setIsChatOpen={setIsChatOpen}
            convertTime={convertTime}
            servicesData={servicesData}
          />
        )}
      </Suspense>

      {/* Footer & Chatbot */}
      <Suspense fallback={<></>}>
        <Footer setIsChatOpen={setIsChatOpen} handlePlatformSelect={handlePlatformSelect} />
        <Chatbot mongoURI={mongoURI} isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} />
        {!isChatOpen && <FooterIcon setIsChatOpen={setIsChatOpen} />}
      </Suspense>
    </>
  );
}

export default App;
