import React, { useState, useRef, useEffect } from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './Header.css';

const Header = ({ setIsChatOpen, handlePlatformSelect, authToken, setAuthToken, mongoURI }) => {
  const [expanded, setExpanded] = useState(false);
  const headerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (headerRef.current && !headerRef.current.contains(event.target)) {
        setExpanded(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClick = () => {
    if (location.pathname === "/") {
      // Scroll to the top if already on the home page
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      // Navigate to the home page
      setExpanded(false);
    }
  };

  const handleLogout = async () => {
    try {
      //convert to fetch
      const response = await fetch(`${mongoURI}deleteSession`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ sessionId: authToken }),
      }).then(response => response.json());
  
      if (response.status === 200) {
        localStorage.removeItem('authToken');
        setAuthToken(null);
        navigate('/');
      } else {
        console.error("Failed to delete session from the backend.");
      }
    } catch (error) {
      console.error("An error occurred during logout:", error);
    }
  };

  return (
    <div className='sticky-top indexLessthanModals'>
      <Navbar expand="lg" expanded={expanded} className="header-navbar" ref={headerRef}>
      <Navbar.Brand
      as={Link}
      to="/"
      className="d-flex align-items-center"
      onClick={handleClick}
    >
      <img
        src='/logo.png'
        width="40"
        height="40"
        className="d-inline-block align-top me-2"
        alt="BuzzBoostr Logo"
      />
      <span className="navbar-title">BuzzBoostr</span>
    </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto align-items-center">
            <Button variant="light" className="ms-3 m-2 header-btn" onClick={() => {
              setExpanded(false);
              navigate('./');
              handlePlatformSelect('Instagram');
            }}>Place New Order</Button>
              
            {window.location.hash !== '#/order-tracking' && (
              <Button variant="light" className="ms-3 m-2 header-btn" onClick={() => {
                setExpanded(false);
                navigate('./order-tracking');
              }}>Track Order</Button>
            )}

            <Button variant="light" className="ms-3 m-2 header-btn" onClick={() => {
              setExpanded(false);
              setIsChatOpen(true);
            }}>FAQ & Support</Button>

            {authToken && (
              <Button 
                variant="danger" 
                className="ms-3 m-2 header-btn" 
                onClick={() => {
                  setExpanded(false);
                  handleLogout();
                }}>
                Logout
              </Button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
